<template>
  <form class="card auth-card" @submit.prevent="registerForm">
    <div class="card-content">
      <span class="card-title">{{$t('authBlock.WellcomeTo',{name:'DY-CRM'})}}</span>
      <div class="input-field">
        <input
          id="email"
          type="text"
          v-model.trim="email"
          :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}"
        >
        <label for="email">{{$t('authBlock.Email')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.email.$dirty && !$v.email.required)"
        >{{$t('authBlock.FieldCouldntBeEmpty')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.email.$dirty && !$v.email.email)"
        >{{$t('authBlock.EmailIncorrect')}}</small>
      </div>
      <div class="input-field">
        <input
          id="password"
          type="password"
          v-model.trim="password"
          :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)}"
        >
        <label for="password">{{$t('authBlock.Password')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.password.$dirty && !$v.password.required)"
        >{{$t('authBlock.InputPassword')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.password.$dirty && !$v.password.minLength)"
        >{{$t('authBlock.PasswordContains', {needAmount: $v.password.$params.minLength.min, currentAmount: password.length})}}</small>
      </div>
      <div class="input-field">
        <input
          id="name"
          type="text"
          v-model="name"
          :class="{invalid: ($v.name.$dirty && !$v.name.required) || ($v.name.$dirty && !$v.name.minLength)}"
        >
        <label for="name">{{$t('authBlock.UserName')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.name.$dirty && !$v.name.required)"
        >{{$t('authBlock.InputUserName')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.name.$dirty && !$v.name.minLength)"
        >{{$t('authBlock.UserNameIsShort')}}</small>
      </div>
      <div class="input-field">
        <input
          id="startBill"
          type="number"
          v-model.number="startBill"
          :class="{invalid: ($v.startBill.$dirty && !$v.startBill.required) || ($v.startBill.$dirty && !$v.startBill.minValue)}"
        >
        <label for="startBill">{{$t('authBlock.CurrentBill')}}</label>
        <small
          class="helper-text invalid"
          v-if="($v.startBill.$dirty && !$v.startBill.required)"
        >{{$t('authBlock.InputBill')}}</small>
        <small
          class="helper-text invalid"
          v-else-if="($v.startBill.$dirty && !$v.startBill.minValue)"
        >{{$t('authBlock.BillCountUnder')}}</small>
      </div>
      <p>
        <label>
          <input type="checkbox"
            v-model="checkbox"
          >
          <span>{{$t('authBlock.AcceptTheRules')}}</span>
        </label>
      </p>
    </div>
    <div class="card-action">
      <div>
        <button
          class="btn waves-effect waves-light auth-submit"
          type="submit"
        >
          {{$t('authBlock.Register')}}
          <i class="material-icons right">send</i>
        </button>
      </div>

      <p class="center">
        {{$t('authBlock.AlReadySignUp')}}
        <router-link to="/login">{{$t('authBlock.Enter')}}</router-link>
      </p>
    </div>
  </form>
</template>

<script>
import { required, minLength, email, minValue } from 'vuelidate/lib/validators'
export default {
  name: 'Register',
  data () {
    return {
      email: '',
      password: '',
      name: '',
      startBill: 0,
      checkbox: false
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Register'
    }
  },
  validations: {
    email: { email, required },
    password: { required, minLength: minLength(10) },
    name: { required, minLength: minLength(2) },
    startBill: { required, minValue: minValue(0) },
    checkbox: { agree: value => value }
  },
  mounted () {
    M.updateTextFields()
  },
  methods: {
    async registerForm () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        email: this.email,
        password: this.password,
        name: this.name,
        bank: this.startBill
      }
      try {
        await this.$store.dispatch('register', formData)
        this.$router.push('/')
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
